import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="relax-logo.png" className="App-logo" alt="logo" />
        <h1>Bienvenue sur RELAX.</h1>
        <p>
          Ce site est désormais privé et accessible uniquement par mot de passe.
        </p>
        <p>RELAX propose un service haut de gamme, voilà pourquoi il est essentiel de conserver une clientèle sélectionnée et fidèle depuis des années.
        </p>
        <p>L'accès à nos services se fait uniquement par parrainage ou sur recommandation d'un proche qui est déjà client chez nous.
        </p>
        <p>Si vous nêtes pas parrainé par un membre existant, il ne sera malheureusement pas possible pour vous de vous abonner.
          Merci de votre compréhension et de votre intérêt pour
          RELAX.</p>
        <div className='reseaux'>
          <a href="https://t.me/+VDGHrektSlpiZGY0" target="_blank" rel="noopener noreferrer">
            <i class="fa-brands fa-telegram" id="reseaux-footer"></i>
          </a>
          <a href="https://wa.me/message/D4OQEYNWDGUHP1" target="_blank" rel="noopener noreferrer">
            <i className="fa-brands fa-whatsapp" id="reseaux-footer"></i>
          </a>
          <a href="mailto:relax-1k@proton.me" target="_blank" rel="noopener noreferrer">
            <i class="fa-solid fa-envelope" id="reseaux-footer"></i>
          </a>
        </div>


        <div className="container">
          <input type="password" id="password" className="password-field" placeholder="Entrez le mot de passe" />
        </div>
      </header>
    </div>
  );
}

export default App;
